// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atendimento-js": () => import("./../../../src/pages/atendimento.js" /* webpackChunkName: "component---src-pages-atendimento-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produtos-js": () => import("./../../../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-recipe-list-js": () => import("./../../../src/templates/recipe-list.js" /* webpackChunkName: "component---src-templates-recipe-list-js" */),
  "component---src-templates-recipe-post-js": () => import("./../../../src/templates/recipe-post.js" /* webpackChunkName: "component---src-templates-recipe-post-js" */),
  "component---src-templates-work-list-js": () => import("./../../../src/templates/work-list.js" /* webpackChunkName: "component---src-templates-work-list-js" */),
  "component---src-templates-work-post-js": () => import("./../../../src/templates/work-post.js" /* webpackChunkName: "component---src-templates-work-post-js" */)
}

